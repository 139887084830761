// services/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { map, Observable, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotAuthGuard {
    constructor(private router: Router,
                private authService: AuthService) {
    }

    canActivate(): Observable<boolean> {
        return this.authService.isAuthenticated$.pipe(
            tap(isAuth => {
                if (isAuth) {
                    console.log('[NotAuthGuard] User authenticated! Can\'t access this page')
                    this.router.navigate(['/dashboard']);
                } else {
                    console.log('[NotAuthGuard] Can activate')
                }
            }),
            map(isAuth => !isAuth)
        );
    }

}
