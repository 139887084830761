import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class ScegliTeamGuard {
    constructor(private router: Router,
                private authService: AuthService,
                private dataService: DataService) {
    }

    canActivate(): Observable<boolean> {
        return this.authService.isAuthenticated$.pipe(
            tap(isAuth => {
                if (!isAuth) {
                    console.log('[ScegliTeamGuard] User not authenticated! Can\'t access this page')
                    this.router.navigate(['/login']);
                }
            }),
            switchMap(isAuth => {
                if (isAuth) {
                    return this.dataService.hasAccount$().pipe(
                        map(hasAccount => {
                            if (hasAccount) {
                                console.log('[ScegliTeamGuard] Already have an account. Can\' access this page')
                                this.router.navigate(['/dashboard']);
                                return false;
                            } else {
                                console.log('[ScegliTeamGuard] Can activate')
                                return true;
                            }
                        })
                    );
                }
                return of(false);
            })
        );
    }

}