import { Component, DestroyRef, HostListener, Inject, inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT, Location, NgIf } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-navbar',
    templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {

    isAuthenticated: boolean | undefined;
    destroyRef = inject(DestroyRef);
    username: string;

    constructor(
        protected router: Router,
        protected authService: AuthService,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {
        this.authService.authenticatedUser
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(authUser => {
                this.isAuthenticated = !!authUser;
                this.username = authUser.email;
            });
    }

    goToLoginPage() {
        this.router.navigate(['login']);
    }

    logout() {
        this.spinner.show();
        this.authService.logout$().subscribe({
            next: value => {
                this.spinner.hide();
                this.router.navigate(['/']);
            }
        });
    }

    goToDsashboardPage() {
        this.router.navigate(['dashboard']);
    }

}
