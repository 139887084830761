import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, concatAll, concatMap, from, map, mergeMap, Observable, switchMap, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { DataArtist, DataRankItem, DataUser } from './data.model';

@Injectable({
    providedIn: 'root'
})
export class DataService {


    constructor(private db: AngularFirestore,
                private authService: AuthService) {

    }

    hasAccount$(): Observable<boolean> {
        return from(
            this.db.collection('users')
                .doc(this.authService.loggedUser?.email)
                .get()
        ).pipe(
            map(document => document.exists),
        );
    }

    createAccount$(username: string, artistId: string): Observable<void> {
        return from(
            this.db.collection('users')
                .doc(this.authService.loggedUser?.email)
                .set({
                    username,
                    artistId
                })
        );
    }

    getUser$(): Observable<DataUser> {
        return this.getRawUser$().pipe(
            switchMap(rawUser =>
                this.getRawArtist$(rawUser.artistId)
                    .pipe(
                        map(artist => ({
                            ...rawUser,
                            artist
                        }))
                    )
            ),
            map(combinedResponse => combinedResponse as DataUser)
        );
    }

    private getRawUser$(): Observable<Partial<DataUser>> {
        return from(
            this.db.collection('users')
                .doc(this.authService.loggedUser?.email)
                .get()
        ).pipe(
            map(response => response.data() as Partial<DataUser>)
        );
    }

    private getRawClassificaGeneraleChanges$(): Observable<Partial<DataRankItem>[]> {
        return from(
            this.db.collection('rankings')
                .doc('general')
                .collection('items')
                .snapshotChanges()
        ).pipe(
            map((documentChange) =>
                documentChange.map(docChange =>
                    ({id: docChange.payload.doc.id, ...docChange.payload.doc.data()}) as DataRankItem)),
        );
    }

    getClassificaGeneraleChanges$(): Observable<DataRankItem[]> {
        return combineLatest([this.getRawClassificaGeneraleChanges$(), this.getArtists$()]).pipe(
            map(([rawRanking, artists]) => {
                return rawRanking.map(item =>
                    ({...item, artist: artists.find(a => a.id === item.artistId)} as DataRankItem))
            })
        );
    }

    private getRawArtist$(artistId: string): Observable<Partial<DataArtist>> {
        return from(
            this.db.collection('artists')
                .doc(artistId)
                .get()
        ).pipe(
            map(response => response.data() as Partial<DataArtist>)
        );
    }

    getArtists$(): Observable<DataArtist[]> {
        return from(
            this.db.collection('artists')
                .get()
        ).pipe(
            map(response => response.docs.map(
                doc => ({...(doc.data() as Partial<DataArtist>), id: doc.id} as DataArtist)))
        );
    }


}
