import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './services/guards/auth.guard';
import { NotAuthGuard } from './services/guards/not-auth.guard';
import { ScegliTeamGuard } from './services/guards/scegli-team.guard';


const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('src/app/pages/login-page/login-page.module')
            .then(m => m.LoginPageModule),
        canActivate: mapToCanActivate([NotAuthGuard]),
    },
    {
        path: 'register',
        loadChildren: () => import('src/app/pages/register-page/register-page.module')
            .then(m => m.RegisterPageModule),
        canActivate: mapToCanActivate([NotAuthGuard]),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('src/app/pages/dashboard-page/dashboard-page.module')
            .then(m => m.DashboardPageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'scegli-team',
        loadChildren: () => import('src/app/pages/scegli-team-page/scegli-team-page.module')
            .then(m => m.ScegliTeamPageModule),
        canActivate: mapToCanActivate([ScegliTeamGuard]),
    },
    {
        path: 'gioca',
        loadChildren: () => import('src/app/pages/gioca-page/gioca-page.module')
            .then(m => m.GiocaPageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'vota',
        loadChildren: () => import('src/app/pages/vota-page/vota-page.module')
            .then(m => m.VotaPageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'completa-la-canzone',
        loadChildren: () => import('src/app/pages/completa-la-canzone-page/completa-la-canzone-page.module')
            .then(m => m.CompletaLaCanzonePageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'regolamento',
        loadChildren: () => import('src/app/pages/regolamento-page/regolamento-page.module')
            .then(m => m.RegolamentoPageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'programma',
        loadChildren: () => import('src/app/pages/programma-serate-page/programma-serate-page.module')
            .then(m => m.ProgrammaSeratePageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'vincitori',
        loadChildren: () => import('src/app/pages/vincitori-page/vincitori-page.module')
            .then(m => m.VincitoriPageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'classifica',
        loadChildren: () => import('src/app/pages/classifica-page/classifica-page.module')
            .then(m => m.ClassificaPageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('src/app/pages/dashboard-page/dashboard-page.module')
            .then(m => m.DashboardPageModule),
        canActivate: mapToCanActivate([AuthGuard]),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () => import('src/app/pages/home-page/home-page.module')
            .then(m => m.HomePageModule),
        canActivate: mapToCanActivate([NotAuthGuard]),
    },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: []
})
export class AppRoutingModule {
}
