import {
    Component,
    OnInit,
    Renderer2,
    HostListener,
    Inject, DestroyRef, inject
} from '@angular/core';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    showNavbar: any;

    constructor(
        private renderer: Renderer2,
        public location: Location,
        @Inject(DOCUMENT) document,
        protected router: Router,
    ) {
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        if (window.pageYOffset > 100) {
            let element = document.getElementById('navbar-top');
            if (element) {
                element.classList.remove('navbar-transparent');
                element.classList.add('bg-navbar');
            }
        } else {
            let element = document.getElementById('navbar-top');
            if (element) {
                element.classList.add('navbar-transparent');
                element.classList.remove('bg-navbar');
            }
        }
    }

    ngOnInit() {
        this.onWindowScroll(event);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.showNavbar = !event.url.includes('login') && !event.url.includes('register');
            }
        });
    }



}
