import { Injectable } from '@angular/core';
import { from, map, Observable, tap } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat';
import UserCredential = firebase.auth.UserCredential;
import User = firebase.User;
import { DataUser } from './data.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public loggedUser: Partial<DataUser>;

    constructor(private afAuth: AngularFireAuth) {
    }

    login$(email: string, password: string): Observable<UserCredential> {
        return from(this.afAuth.signInWithEmailAndPassword(email, password))
            .pipe(
                tap(logged => this.loggedUser = {
                    email: logged.user.email
                })
            );
    }

    loginWithGoogle$() {
        return from(this.afAuth.signInWithPopup(new GoogleAuthProvider()))
            .pipe(
                tap(logged => this.loggedUser = {
                    email: logged.user.email
                })
            );
    }

    logout$(): Observable<void> {
        console.log(this.afAuth.currentUser)
        return from(this.afAuth.signOut())
            .pipe(
                tap(logged => this.loggedUser = undefined)
            );
    }

    register$(username: string, email: string, password: string): Observable<UserCredential> {
        return from(this.afAuth.createUserWithEmailAndPassword(email, password))
            .pipe(
                tap(logged => this.loggedUser = {
                    email: logged.user.email
                })
            );
    }

    get isAuthenticated$(): Observable<boolean> {
        return from(this.afAuth.authState).pipe(
            map(currentUser => {
                this.loggedUser = {
                    email: currentUser?.email
                }
                return currentUser !== null;
            }),
        );
    }

    get authenticatedUser(): Observable<User> {
        return from(this.afAuth.user);
    }

}