// services/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { DataService } from '../data.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(private router: Router,
                private authService: AuthService,
                private dataService: DataService,
                private spinner: NgxSpinnerService) {
    }

    canActivate(): Observable<boolean> {
        this.spinner.show();

        return this.authService.isAuthenticated$.pipe(
            tap(isAuth => {
                if (!isAuth) {
                    console.log('[AuthGuard] User not authenticated! Can\'t access this page')
                    this.router.navigate(['/login']);
                }
            }),
            switchMap(isAuth => {
                if (isAuth) {
                    return this.dataService.hasAccount$().pipe(
                        tap(hasAccount => {
                            if (!hasAccount) {
                                console.log('[AuthGuard] User has not created the account yet. Redirect to create team page!')
                                this.router.navigate(['/scegli-team']);
                            } else {
                                console.log('[AuthGuard] Can activate')
                            }
                        })
                    );
                }
                return of(false);
            })
        );
    }

}